import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import '../styles/Events.css';
import { useNavigate } from 'react-router-dom';
import EventDetail from '../components/EventDetail';
import EventCard from '../components/EventCard';
import { useTranslation } from 'react-i18next';

export const Events = () => {
  const { t } = useTranslation(); // Use translation hook
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(state => state.auth.value);
  const [events, setEvents] = useState([]);
  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [registrationMessage, setRegistrationMessage] = useState(null);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const accessToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

      if (accessToken) {
        dispatch(setAuth(true));
        setShowLoginPrompt(false);
      } else {
        dispatch(setAuth(false));
        setShowLoginPrompt(true);
      }
    };

    checkAuth();
  }, [dispatch, navigate]);

  useEffect(() => {
    const fetchCitiesCategoriesAndEvents = async () => {
      try {
        const citiesResponse = await axios.get('content/v1/cities/');
        setCities(citiesResponse.data);

        const categoriesResponse = await axios.get('content/v1/sport-categories/');
        setCategories(categoriesResponse.data);

        await fetchEvents();
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchCitiesCategoriesAndEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const accessToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

      if (!accessToken) {
        setShowLoginPrompt(true);
        return;
      }

      let url = 'content/v1/user-event/';
      const params = [];

      if (selectedCity && selectedCity !== t('events_page.all_cities_option')) {
        params.push(`city=${encodeURIComponent(selectedCity)}`);
      }

      if (selectedCategory && selectedCategory !== t('events_page.all_categories_option')) {
        params.push(`category=${encodeURIComponent(selectedCategory)}`);
      }

      if (selectedDate) {
        params.push(`event_date=${encodeURIComponent(selectedDate)}`);
      }

      if (params.length > 0) {
        url += '?' + params.join('&');
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      setEvents(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching events", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth) {
      fetchEvents();
    }
  }, [selectedCity, selectedCategory, selectedDate, auth]);

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleEventClick = async (eventId) => {
    try {
      const accessToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

      if (!accessToken) {
        setShowLoginPrompt(true);
        return;
      }

      const response = await axios.get(`content/v1/user-event/${eventId}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      setSelectedEvent(response.data);
    } catch (error) {
      console.error("Error fetching event details", error);
    }
  };

  const handleRegister = async () => {
    try {
      const accessToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

      if (!accessToken) {
        setShowLoginPrompt(true);
        return;
      }

      await axios.post(`user_actions/v1/events/${selectedEvent.id}/register/`, {}, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      setRegistrationMessage(`${t('events_page.registration_success')} ${selectedEvent.name}!`);
      setSelectedEvent(null);
    } catch (error) {
      console.error("Error registering for event", error);
      setRegistrationMessage(t('events_page.registration_error'));
    }
  };

  return (
    <div className="relative pt-2">
      {showLoginPrompt && (
        <>
          <div className="inset-0 bg-gray-800 bg-opacity-70 z-40"></div>
          <div className="inset-0 flex items-center justify-center z-50 p-4">
            <div className="bg-white p-6 sm:p-8 md:p-10 lg:p-12 xl:p-16 rounded-lg shadow-lg max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto relative">
              <h2 className="text-lg sm:text-xl font-semibold mb-4">{t('events_page.login_prompt_title')}</h2>
              <p className="mb-4 text-sm sm:text-base">
                {t('events_page.login_prompt_description')}
              </p>
              <p className="mb-4 text-sm sm:text-base">
                {t('events_page.login_prompt_reminder')}
              </p>
              <div className="flex flex-col sm:flex-row gap-2">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex-1"
                  onClick={() => navigate('/login')}
                >
                  {t('events_page.button_login')}
                </button>
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex-1"
                  onClick={() => navigate('/ordinary-user-signup')}
                >
                  {t('events_page.button_register')}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className={`container mx-auto px-4 py-6 ${showLoginPrompt ? 'blur-sm' : ''}`}>
        <h1 className="text-3xl font-bold mb-4">{t('events_page.heading_games')}</h1>
        <div className="filter mb-6 p-4 bg-white rounded shadow-md">
          <div className="flex flex-col space-y-4">
            <div className="flex items-center">
              <label htmlFor="city" className="mr-2 font-semibold">{t('events_page.filter_city_label')}</label>
              <select id="city" value={selectedCity} onChange={handleCityChange} className="border px-2 py-1 rounded">
                <option value="">{t('events_page.all_cities_option')}</option>
                {cities.map((city, index) => (
                  <option key={index} value={city.name}>{city.name}</option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <label htmlFor="category" className="mr-2 font-semibold">{t('events_page.filter_category_label')}</label>
              <select id="category" value={selectedCategory} onChange={handleCategoryChange} className="border px-2 py-1 rounded">
                <option value="">{t('events_page.all_categories_option')}</option>
                {categories.map((category, index) => (
                  <option key={index} value={category.name}>{category.name}</option>
                ))}
              </select>
            </div>
            <div className="flex items-center">
              <label htmlFor="event_date" className="mr-2 font-semibold">{t('events_page.filter_date_label')}</label>
              <input
                type="date"
                id="event_date"
                value={selectedDate}
                onChange={handleDateChange}
                className="border px-2 py-1 rounded"
              />
            </div>
          </div>
        </div>

        {/* Conditionally render "No events" if the events array is empty */}
        {loading ? (
          <p>{t('events_page.loading')}</p>
        ) : events.length === 0 ? (
          <p>{t('events_page.no_events')}</p>
        ) : (
          <ul className="events-list grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {events.map((event) => (
              <EventCard key={event.id} event={event} onClick={() => handleEventClick(event.id)} />
            ))}
          </ul>
        )}

        {selectedEvent && (
          <EventDetail
            event={selectedEvent}
            onClose={() => setSelectedEvent(null)}
            showRegisterButton={true}
            handleRegister={handleRegister}
            registrationMessage={registrationMessage}
          />
        )}
      </div>
      <br />
    </div>
  );
};
