import './App.css';
import React from 'react';
import { Login } from './components/Login';
import {OrdinaryUserSignup} from './components/OrdinaryUserSignup';
import {BrowserRouter, Routes, Route, HashRouter} from 'react-router-dom';
import {Events} from "./pages/events";
import Locations from "./pages/locations";
import Navbar  from './components/Navbar';
import { User } from './components/User';
import { OrdinaryUserProfileCreation } from './components/OrdinaryUserProfileCreation';
import { OrdinaryUserProfile } from './components/OrdinaryUserProfile';
import MapPage from './pages/Locs';
import Scoreboard  from './pages/Scoreboard';
import Footer from './components/Footer';
import LocationDetails from './components/LocationDetails';
import "./i18n";
import { useTranslation } from 'react-i18next';


export const App = () => {

  
  return <BrowserRouter>
     <Navbar />
     <Routes>
       <Route path='/login' element={<Login/>} />
       <Route path='/ordinary-user-signup' element={<OrdinaryUserSignup/>} />
       <Route path="/events" element={<Events />} />
       <Route path="/" element={<Locations />} />
       <Route path="/user" element={<User />} />
       <Route path='/create-ordinary-user-profile' element={<OrdinaryUserProfileCreation />} />
       <Route path='/profile' element={<OrdinaryUserProfile />} />
       <Route path='/locations' element={<MapPage/>} />
       <Route path='/scoreboard' element={<Scoreboard />}/>
       <Route path="/location/:id" element={<LocationDetails />} />
     </Routes>
     <Footer/>
   </BrowserRouter>
 }


export default App;