import React, { useState, useEffect } from 'react';
import MapboxMap from '../components/Map';
import SearchBar from '../components/Searchbar';
import AddCourt from '../components/AddCourt';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import '../components/Locs.css';
import mapboxgl from 'mapbox-gl';
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding';
import { useTranslation } from 'react-i18next';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;

const MapPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth.value);
  const [showAddCourt, setShowAddCourt] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [cities, setCities] = useState([]);
  const [selectedCityName, setSelectedCityName] = useState('');
  const [selectedCityCoordinates, setSelectedCityCoordinates] = useState(null);
  const [locations, setLocations] = useState([]);
  const [createdByFilter, setCreatedByFilter] = useState('all');
  const [filteredLocations, setFilteredLocations] = useState([]);

  const geocodingClient = mbxGeocoding({ accessToken: mapboxgl.accessToken });

  useEffect(() => {
    const checkAuth = () => {
      const access_token = document.cookie
        .split('; ')
        .find((row) => row.startsWith('access_token='))
        ?.split('=')[1];

      if (access_token) {
        dispatch(setAuth(true));
      } else {
        dispatch(setAuth(false));
      }
    };

    checkAuth();
  }, [dispatch]);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get('content/v1/cities/');
        setCities(response.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, []);

  const fetchLocations = async () => {
    try {
      const response = await axios.get('content/v1/locations/');
      setLocations(response.data);
      setFilteredLocations(response.data);
    } catch (error) {
      console.error('Error fetching locations:', error);
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const geocodeAddress = async (address) => {
    try {
      const response = await geocodingClient
        .forwardGeocode({
          query: address,
          limit: 1,
        })
        .send();

      if (response.body && response.body.features && response.body.features.length > 0) {
        const [lng, lat] = response.body.features[0].center;
        return { lat, lng };
      } else {
        console.error('No results found for address:', address);
        return null;
      }
    } catch (error) {
      console.error('Error geocoding address:', error);
      return null;
    }
  };

  const handleCityChange = async (event) => {
    const cityName = event.target.value;
    setSelectedCityName(cityName);
    const geocodedCity = await geocodeAddress(cityName);
    if (geocodedCity) {
      setSelectedCityCoordinates(geocodedCity);
      setSelectedLocation(null);
      console.log('Selected city coordinates:', geocodedCity);
    } else {
      console.error('Failed to geocode city:', cityName);
    }
  };

  const handleCreatedByChange = (event) => {
    setCreatedByFilter(event.target.value);
  };

  const handleToggleAddCourt = () => {
    setShowAddCourt(!showAddCourt);
  };

  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
  };

  useEffect(() => {
    const updatedFilteredLocations = locations.filter(
      (location) => createdByFilter === 'all' || location.created_by === createdByFilter.toUpperCase()
    );
    setFilteredLocations(updatedFilteredLocations);
    console.log('Filtered locations:', updatedFilteredLocations);
  }, [createdByFilter, locations]);

  return (
    <div className="container">
      <section className="infor">
        <div className="infor-item">
          <center>
            <h3>{t('map_page.heading_courts_collected')}</h3>
          </center>
          <center>
            <p>{t('map_page.instructions_choose_city')}</p>
          </center>
          <center>
            <p>{t('map_page.instructions_add_court')}</p>
          </center>
          <center>
            <button className="toggle-button" onClick={handleToggleAddCourt}>
              {showAddCourt ? 'Add!' : 'Add!'}
            </button>
          </center>
          <div className="separator"></div>
        </div>
      </section>

      {showAddCourt && (
        <div className="modal-overlay" onClick={handleToggleAddCourt}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={handleToggleAddCourt}>
              ×
            </button>
            <AddCourt />
          </div>
        </div>
      )}

      <header className="header">
        <select
          className="dropdown"
          value={selectedCityName}
          onChange={handleCityChange}
        >
          <option value="">{t('map_page.dropdown_select_city')}</option>
          {cities.map((city, index) => (
            <option key={index} value={city.name}>
              {city.name}
            </option>
          ))}
        </select>
        <select
          className="dropdown"
          value={createdByFilter}
          onChange={handleCreatedByChange}
        >
          <option value="all">{t('map_page.location_option_all')}</option>
          <option value="ORDINARY">{t('map_page.location_option_ordinary')}</option>
          <option value="HOST">{t('map_page.location_option_host')}</option>
        </select>
        <SearchBar onSelect={setSelectedLocation} />
      </header>

      <div className="map-container" style={{ marginBottom: '100px' }}>
        <MapboxMap
          key={`${createdByFilter}-${selectedCityCoordinates?.lat}-${selectedCityCoordinates?.lng}`}
          selectedLocation={selectedLocation}
          selectedCityCoordinates={selectedCityCoordinates}
          locations={filteredLocations}
          setSelectedLocation={setSelectedLocation}
          onMarkerClick={handleMarkerClick}
        />
      </div>
    </div>
  );
};

export default MapPage;
