import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const savedLanguage = document.cookie
  .split('; ')
  .find(row => row.startsWith('i18next='))
  ?.split('=')[1] || 'en';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        welcome: "Welcome",
        description: "This is a multilingual app",
        locations : "Locations",
        events : "Events",
        scoreboard : "Scoreboard",
        searchbar: {
          "search_placeholder": "Search..."
        },
        home_page: {
          "feature_map_local_courts": {
            "name": "Map of local courts.",
            "description": "Go to the 'Courts' tab and find the one that's perfect for you. Or, add your own court that is not yet on the map, and we'll review and publish it."
          },
          "feature_auto_schedule": {
            "name": "Auto-updated game schedule.",
            "description": "Go to the 'Events' tab and enjoy the latest upcoming games, with the ability to filter by city, sports category, and date."
          },
          "feature_ranking": {
            "name": "Ranking.",
            "description": "Track your progress and compete with other users. The most active participants on the leaderboard will be rewarded with cash prizes."
          },
          "hero_title": "What is Pocket Sport?",
          "hero_description": "It is a platform that allows you to find and add local sports courts, as well as create games on them and join existing ones.",
          "how_it_works_title": "How does it work?",
          "how_it_works_steps": [
            "Sign up or log in.",
            "Add your local sports courts.",
            "Search for games that suit you and join them."
          ],
          "cta_find_courts": "Find courts",
          "cta_create_join_games": "❤️ Create and join games whenever you want",
          "cta_create_join_description": "Now it's easy and fast"
        },
        navbar : {
          "navigation_locations": "Locations",
          "navigation_events": "Events",
          "navigation_scoreboard": "Scoreboard",
          "button_logout": "Logout",
          "button_profile": "Profile",
          "button_signup": "Sign up",
          "button_login": "Login",
          "mobile_menu_open": "Open main menu",
          "mobile_menu_close": "Close menu",
          "login": "Login",
          "sign_up": "Sign up"
        },
        profile_page: {
          "loading_message": "Loading...",
          "error_message": "You are not authenticated",
          "profile_deleted_success": "Profile deleted successfully.",
          "profile_deleted_error": "Failed to delete profile.",
          "title_profile": "Profile",
          "label_email": "Email",
          "label_birthdate": "Birthdate",
          "label_location": "Location",
          "label_description": "Description",
          "label_favorite_categories": "Favorite Categories",
          "label_points": "Points",
          "label_user_level": "User Level",
          "title_my_games": "My Games",
          "tab_past_events": "Past games",
          "tab_future_events": "Future games",
          "no_games_message": "No games",
          "button_details": "Details",
          "button_unregister": "Unregister",
          "button_delete_account": "Delete Account",
          "modal_delete_account_title": "Delete Account",
          "modal_delete_account_message": "Are you sure you want to delete your profile? This action cannot be undone.",
          "modal_confirm_button": "Yes, delete",
          "modal_cancel_button": "Cancel",
          "unregister_success_message": "Successfully unregistered from the event.",
          "unregister_error_message": "Failed to unregister from the event.",
          "access_token_error": "Access token not found."
        },
        map_page : {
          "heading_courts_collected": "Courts collected for you!",
          "instructions_choose_city": "Choose your city, find the most convenient court for you, join an existing game, or create your own!",
          "instructions_add_court": "Add a court in your area:",
          "button_add": "Add!",
          "dropdown_select_city": "Select city",
          "location_option_all": "All",
          "location_option_ordinary": "Public",
          "location_option_host": "Private",
          "alert_refresh_map": "If the courts don't appear on the map immediately, refresh the page again.",
          "error_fetch_cities": "Error fetching cities:",
          "error_geocode_city": "Failed to geocode the city"
        },
        event_detail: {
          "loading": "Loading...",
          "label_location": "Location",
          "label_category": "Category",
          "label_address": "Address",
          "label_date": "Date",
          "label_time": "Time",
          "label_duration": "Duration",
          "label_description": "Description",
          "label_participants": "Participants",
          "loading_participants": "Loading participants...",
          "register_button": "Register",
          "registration_success": "Successfully registered!"
        },
        event_card: {
          "label_category": "Category",
          "label_date": "Date",
          "label_time": "Time",
          "label_address": "Address"
        },
        events_page: {
          "heading_games": "Games",
          "filter_city_label": "Select City:",
          "filter_category_label": "Select Category:",
          "filter_date_label": "Select Date:",
          "login_prompt_title": "Log in or Sign up",
          "login_prompt_description": "To access the website features, you need to log in or create an account with a profile.",
          "login_prompt_reminder": "If you skip creating a profile, you will not be able to log in with your email.",
          "button_login": "Log in",
          "button_register": "Sign up",
          "registration_success": "Successfully registered for the game",
          "registration_error": "Registration error",
          "all_cities_option": "All cities",
          "all_categories_option" : "All categories",
          no_events: "No events"
        },
        login_page: {
          "heading": "Login",
          "label_email": "Email",
          "label_password": "Password",
          "button_login": "Login",
          "login_error": "Error during login",
          "loading_message": "Loading...",
          "signup_prompt": "Don't have a profile yet?",
          "signup_link": "Signup"
        },
        signup: {
          title: "Signup",
          email: "Email",
          username: "Username",
          password: "Password",
          passwordConfirm: "Confirm password",
          submitButton: "Signup",
          existingProfile: "Already have a profile?",
          loginLink: "Login",
          error: "An unexpected error occurred. Please try again later.",
          loading: "Loading...",
        },
        footer: {
          footer_phrase: "Uniting sportsmen since 2024!",
          feedback: "Feedback form"
        },
        profile_creation: {
          error: "An unexpected error occurred. Please try again later.",
          loading: "Loading...",
          title: "Creating a profile",
          warningMessage: "If you skip the step of creating a profile, you will not be able to log in with your email!",
          dob: "Date of birth",
          user_location: "Your location",
          choose_city: "Choose city",
          profile_pic: "Profile picture (optional)",
          description: "Description (optional)",
          preferredCategories: "Preferred Categories",
          resetButton: "Reset",
          createProfileButton: "Create Profile"
        },
        add_court_component: {
          error: "An unexpected error occurred. Please try again later.",
          location_exists_error: "location submission with this name already exists.",
          location_name_error: "Ensure this field has no more than 50 characters.",
          network_error: "Network error. Please check your internet connection.",
          add_court_message: "Add court!",
          success_message: "Location is successfully added!",
          city: "City",
          chooseCity: "Choose your city",
          address: "Address",
          add_location_name: "Name the court",
          brief_description: "Short description (how to get through)",
          dragDrop: "Drag & drop, upload a photo of the court",
          accepted_files: "Accepted files:",
          reset: "Reset",
          add: "Add"
        },
        location_card_component: {
          details: "Details",
        },
        location_details_component: {
          login_or_register: "Login or Register",
          login_prompt_message: "To access site features, you need to log in or create an account with a profile.",
          login: "Login",
          register: "Register",
          create_event: "Create event",
          leave_a_review: "Leave a Review",
          past_events: "Past events",
          future_events: "Future events",
          no_events: "No events",
          loading: "Loading...",
        },
        create_game_form: {
          game_created: "The game has been successfully created!",
          game_name: "Name",
          game_date: "Date",
          game_start_time: "Start time",
          game_duration: "Duration",
          choose_game_duration: "Choose game duration",
          hour_1: "1 hour",
          hour_1_min_30: "1 hour 30 mins",
          hours_2: "2 hours",
          hours_2_mins_30: "2 hours 30 mins",
          hours_3: "3 hours",
          description: "Description",
          participants: "Participants",
          choose_max_participants: "Select the max. number of participants",
          category: "Category",
          choose_sports_category: "Select a sports category",
          reset: "Reset",
          create: "Create",
        },
        location_review_component: {
          success_message: " Review is submitted successfully!",
          thanks_message: "Thank you for your feedback!",
          leave_review: "Leave a Review",
          comment_optional: "Comment (optional)",
          rating: "Rating",
          reset: "Reset",
          submit: "Submit",
          comment_placeholder: "Leave your comment here",
          label_select_rating: "Select rating",
        },
        scoreboard_page: {
          login_prompt: "Login or Register",
          propmpt_message_1: "To access the site's functions, you need to log in or create an account with a profile",
          propmpt_message_2: "If you skip the step of creating a profile, you will not be able to log in under your email",
          login: "Login",
          register: "Register",
          scoreboard_title: "Scoreboard",
          points: "Points",
          total_games: "Total games",
          loading: "Loading...",
          profile_pic: "Photo",
          username: "Username",
          user_level: "User level",
          user_location: "User location",
          date_registered: "Date registered",
          no_users: "No users found",
        },
        event_card_detail: {
          
        }
      },
    },
    fr: {
      translation: {
        welcome: "Bienvenue",
        description: "C'est une application multilingue",
        description: "This is a multilingual app",
        locations: "Pistes",
        events: "Événements",
        scoreboard: "Tableau des scores",
        searchbar: {
          "search_placeholder": "Chercher..."
        },
        home_page : {
            "feature_map_local_courts": {
              "name": "Carte des terrains locaux.",
              "description": "Accédez à l'onglet 'Terrains' et trouvez celui qui vous convient le mieux. Ou ajoutez votre propre terrain qui n'est pas encore sur la carte, et nous l'examinerons et le publierons."
            },
            "feature_auto_schedule": {
              "name": "Calendrier des jeux mis à jour automatiquement.",
              "description": "Accédez à l'onglet 'Événements' et profitez des prochains jeux, avec la possibilité de filtrer par ville, catégorie sportive et date."
            },
            "feature_ranking": {
              "name": "Classement.",
              "description": "Suivez vos progrès et rivalisez avec d'autres utilisateurs. Les participants les plus actifs du classement seront récompensés par des prix en argent."
            },
            "hero_title": "Qu'est-ce que Pocket Sport ?",
            "hero_description": "C'est une plateforme qui vous permet de trouver et d'ajouter des terrains de sport locaux, ainsi que de créer des jeux dessus et de rejoindre ceux déjà existants.",
            "how_it_works_title": "Comment ça marche ?",
            "how_it_works_steps": [
              "Inscrivez-vous ou connectez-vous.",
              "Ajoutez vos terrains de sport locaux.",
              "Recherchez des jeux qui vous conviennent et rejoignez-les."
            ],
            "cta_find_courts": "Trouver des terrains",
            "cta_create_join_games": "❤️ Créez et rejoignez des jeux quand vous voulez",
            "cta_create_join_description": "Maintenant c'est facile et rapide"
          },
          "navbar": {
            "navigation_locations": "Terrains",
            "navigation_events": "Événements",
            "navigation_scoreboard": "Classement",
            "button_logout": "Déconnexion",
            "button_profile": "Profil",
            "button_signup": "S'inscrire",
            "button_login": "Connexion",
            "mobile_menu_open": "Ouvrir le menu principal",
            "mobile_menu_close": "Fermer le menu",
            "login": "Connexion",
            "sign_up": "S'inscrire"
          },
          profile_page: {
            "loading_message": "Chargement...",
            "error_message": "Vous n'êtes pas authentifié",
            "profile_deleted_success": "Profil supprimé avec succès.",
            "profile_deleted_error": "Échec de la suppression du profil.",
            "title_profile": "Profil",
            "label_email": "E-mail",
            "label_birthdate": "Date de naissance",
            "label_location": "Localisation",
            "label_description": "Description",
            "label_favorite_categories": "Catégories favorites",
            "label_points": "Points",
            "label_user_level": "Niveau d'utilisateur",
            "title_my_games": "Mes jeux",
            "tab_past_events": "Jeux passés",
            "tab_future_events": "Jeux futurs",
            "no_games_message": "Aucun jeu",
            "button_details": "Détails",
            "button_unregister": "Se désinscrire",
            "button_delete_account": "Supprimer le compte",
            "modal_delete_account_title": "Supprimer le compte",
            "modal_delete_account_message": "Êtes-vous sûr de vouloir supprimer votre profil ? Cette action est irréversible.",
            "modal_confirm_button": "Oui, supprimer",
            "modal_cancel_button": "Annuler",
            "unregister_success_message": "Désinscription réussie de l'événement.",
            "unregister_error_message": "Échec de la désinscription de l'événement.",
            "access_token_error": "Jeton d'accès non trouvé."
          },
          map_page: {
            "heading_courts_collected": "Terrains collectés pour vous !",
            "instructions_choose_city": "Choisissez votre ville, trouvez le terrain le plus pratique pour vous, rejoignez un jeu existant ou créez le vôtre !",
            "instructions_add_court": "Ajoutez un terrain dans votre région :",
            "button_add": "Ajouter !",
            "dropdown_select_city": "Sélectionnez une ville",
            "location_option_all": "Tous",
            "location_option_ordinary": "Public",
            "location_option_host": "Privé",
            "alert_refresh_map": "Si les terrains n'apparaissent pas immédiatement sur la carte, rafraîchissez la page.",
            "error_fetch_cities": "Erreur lors de la récupération des villes :",
            "error_geocode_city": "Échec du géocodage de la ville"
          },
          events_page: {
            "heading_games": "Jeux",
            "filter_city_label": "Sélectionnez la ville :",
            "filter_category_label": "Sélectionnez la catégorie :",
            "filter_date_label": "Sélectionnez la date :",
            "login_prompt_title": "Connectez-vous ou inscrivez-vous",
            "login_prompt_description": "Pour accéder aux fonctionnalités du site, vous devez vous connecter ou créer un compte avec un profil.",
            "login_prompt_reminder": "Si vous sautez l'étape de création du profil, vous ne pourrez pas vous connecter avec votre e-mail.",
            "button_login": "Se connecter",
            "button_register": "S'inscrire",
            "registration_success": "Inscription réussie pour le jeu",
            "registration_error": "Erreur d'inscription",
            "all_cities_option": "Toutes les villes",
            "all_categories_option": "Toutes les catégories",
            no_events: "Aucun événement"
          },
          event_detail: {
            "loading": "Chargement...",
            "label_location": "Lieu",
            "label_category": "Catégorie",
            "label_address": "Adresse",
            "label_date": "Date",
            "label_time": "Heure",
            "label_duration": "Durée",
            "label_description": "Description",
            "label_participants": "Participants",
            "loading_participants": "Chargement des participants...",
            "register_button": "S'inscrire",
            "registration_success": "Inscription réussie !"
          },
          event_card: {
            "label_category": "Catégorie",
            "label_date": "Date",
            "label_time": "Heure",
            "label_address": "Adresse"
          },
          login_page: {
            "heading": "Connexion",
            "label_email": "E-mail",
            "label_password": "Mot de passe",
            "button_login": "Se connecter",
            "login_error": "Erreur lors de la connexion",
            "loading_message": "Chargement...",
            "signup_prompt": "Vous n'avez pas encore de profil ?",
            "signup_link": "S'inscrire"
          },
          signup: {
            title: "Inscription",
            email: "E-mail",
            username: "Nom d'utilisateur",
            password: "Mot de passe",
            passwordConfirm: "Confirmer le mot de passe",
            submitButton: "S'inscrire",
            existingProfile: "Vous avez déjà un profil?",
            loginLink: "Connexion",
            error: "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
          },
          footer: {
            "footer_phrase": "Unissant les sportifs depuis 2024 !",
            "feedback": "Retour d'information"
          },
          profile_creation: {
              "error": "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
              "loading": "Chargement...",
              "title": "Création d'un profil",
              "warningMessage": "Si vous sautez l'étape de création d'un profil, vous ne pourrez pas vous connecter avec votre e-mail !",
              "dob": "Date de naissance",
              "user_location": "Votre localisation",
              "choose_city": "Choisir une ville",
              "profile_pic": "Photo de profil (facultatif)",
              "description": "Description (facultatif)",
              "preferredCategories": "Catégories préférées",
              "resetButton": "Réinitialiser",
              "createProfileButton": "Créer un profil"
          },
          add_court_component: {
              "error": "Une erreur inattendue est survenue. Veuillez réessayer plus tard.",
              "location_exists_error": "Un emplacement avec ce nom existe déjà.",
              "location_name_error": "Assurez-vous que ce champ ne comporte pas plus de 50 caractères.",
              "network_error": "Erreur réseau. Veuillez vérifier votre connexion internet.",
              "add_court_message": "Ajouter un terrain !",
              "success_message": "Emplacement ajouté avec succès !",
              "city": "Ville",
              "chooseCity": "Choisissez votre ville",
              "address": "Adresse",
              "add_location_name": "Nom du terrain",
              "brief_description": "Courte description (comment y accéder)",
              "dragDrop": "Glissez-déposez, téléchargez une photo du terrain",
              "accepted_files": "Fichiers acceptés :",
              "reset": "Réinitialiser",
              "add": "Ajouter"
          },
          location_card_component: {
            "details": "Détails"
          },
          location_details_component: {
            "login_or_register": "Connexion ou inscription",
            "login_prompt_message": "Pour accéder aux fonctionnalités du site, vous devez vous connecter ou créer un compte avec un profil.",
            "login": "Connexion",
            "register": "Inscription",
            "create_event": "Créer un événement",
            "leave_a_review": "Laisser un avis",
            "past_events": "Événements passés",
            "future_events": "Événements à venir",
            "no_events": "Aucun événement",
            "loading": "Chargement...",
        },
        create_game_form: {
          "game_created": "Le jeu a été créé avec succès!",
          "game_name": "Nom",
          "game_date": "Date",
          "game_start_time": "Heure de début",
          "game_duration": "Durée",
          "choose_game_duration": "Choisissez la durée du jeu",
          "hour_1": "1 heure",
          "hour_1_min_30": "1 heure 30 minutes",
          "hours_2": "2 heures",
          "hours_2_mins_30": "2 heures 30 minutes",
          "hours_3": "3 heures",
          "description": "Description",
          "participants": "Participants",
          "choose_max_participants": "Sélectionnez le nombre max. de participants",
          "category": "Catégorie",
          "choose_sports_category": "Sélectionnez une catégorie sportive",
          "reset": "Réinitialiser",
          "create": "Créer",
        },
        location_review_component: {
          "success_message": "L'avis a été soumis avec succès!",
          "thanks_message": "Merci pour votre retour!",
          "leave_review": "Laisser un avis",
          "comment_optional": "Commentaire (optionnel)",
          "rating": "Évaluation",
          "reset": "Réinitialiser",
          "submit": "Soumettre",
          "comment_placeholder": "Laissez votre commentaire ici",
          "label_select_rating": "Sélectionner une évaluation",
        },
        scoreboard_page: {
          "login_prompt": "Connexion ou Inscription",
          "propmpt_message_1": "Pour accéder aux fonctionnalités du site, vous devez vous connecter ou créer un compte avec un profil",
          "propmpt_message_2": "Si vous sautez l'étape de création de profil, vous ne pourrez pas vous connecter avec votre email",
          "login": "Connexion",
          "register": "Inscription",
          "scoreboard_title": "Classement",
          "points": "Points",
          "total_games": "Total des parties",
          "loading": "Chargement...",
          "profile_pic": "Photo",
          "username": "Nom d'utilisateur",
          "user_level": "Niveau d'utilisateur",
          "user_location": "Lieu de l'utilisateur",
          "date_registered": "Date d'inscription",
          "no_users": "Aucun utilisateur trouvé",
        },
      },
    },
  },
  lng: savedLanguage,
  fallbackLng: "en",
});

export default i18n;