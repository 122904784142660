import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../redux/authSlice';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Scoreboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [sortBy, setSortBy] = useState('points');
  const [loading, setLoading] = useState(true);
  const auth = useSelector(state => state.auth.value);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      const access_token = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

      if (!access_token) {
        console.log('Access Token not found');
        setLoading(false);
        setShowLoginPrompt(true);
        return;
      }

      setLoading(true);
      setShowLoginPrompt(false);
      const params = {
        sort_by: sortBy,
      };

      try {
        const response = await axios.get('content/v1/scoreboard/', {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
          params,
        });
        console.log('Response:', response.data);
        setUsers(response.data.data || []);
        setLoading(false);
        dispatch(setAuth(true));
        setShowLoginPrompt(false);
      } catch (error) {
        console.error('Error fetching scoreboard data:', error);
        setLoading(false);
        dispatch(setAuth(false));
        setShowLoginPrompt(true);
      }
    };

    fetchUsers();
  }, [sortBy, dispatch]);

  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
  };

  return (
    <div className="relative">
      {showLoginPrompt && (
        <>
          <div className="inset-0 bg-gray-800 bg-opacity-70 z-40"></div>
          <div className="inset-0 flex items-center justify-center z-50 p-4">
            <div className="bg-white p-6 sm:p-8 md:p-10 lg:p-12 xl:p-16 rounded-lg shadow-lg max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto relative">
              <h2 className="text-lg sm:text-xl font-semibold mb-4">{t("scoreboard_page.login_prompt")}</h2>
              <p className="mb-4 text-sm sm:text-base">
                {t("scoreboard_page.propmpt_message_1")}
              </p>
              <p className="mb-4 text-sm sm:text-base">
                {t("scoreboard_page.propmpt_message_2")}
              </p>
              <div className="flex flex-col sm:flex-row gap-2">
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 flex-1"
                  onClick={() => navigate('/login')}
                >
                  {t("scoreboard_page.login")}
                </button>
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 flex-1"
                  onClick={() => navigate('/ordinary-user-signup')}
                >
                  {t("scoreboard_page.register")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      <div className={`mt-6 overflow-x-auto ${showLoginPrompt ? 'blur-sm' : ''}`}>
        <div className="flex justify-center">
          <h2 className="my-4 text-2xl font-bold">{t("scoreboard_page.scoreboard_title")}</h2>
        </div>
        <div className="flex justify-center mb-3">
          <select
            id="sortBy"
            className="border px-2 py-1 rounded"
            value={sortBy}
            onChange={handleSortByChange}
          >
            <option value="points">{t("scoreboard_page.points")}</option>
            <option value="total_games">{t("scoreboard_page.total_games")}</option>
          </select>
        </div>
        {loading ? (
          <p>{t("scoreboard_page.loading")}</p>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
              <thead className="bg-gray-50 border-b border-gray-300">
                <tr>
                  <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">#</th>
                  <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">{t("scoreboard_page.profile_pic")}</th>
                  <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">{t("scoreboard_page.username")}</th>
                  <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">{t("scoreboard_page.points")}</th>
                  <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">{t("scoreboard_page.total_games")}</th>
                  <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">{t("scoreboard_page.user_level")}</th>
                  <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">{t("scoreboard_page.user_location")}</th>
                  <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">{t("scoreboard_page.date_registered")}</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users.length > 0 ? (
                  users.map((user, index) => (
                    <tr key={user.id}>
                      <td className="px-6 py-4 text-center text-sm font-medium text-gray-900">{index + 1}</td>
                      <td className="px-6 py-4 text-center">
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}${user.profile_picture}`}
                          alt="Profile"
                          className="w-12 h-12 rounded-full mx-auto"
                        />
                      </td>
                      <td className="px-6 py-4 text-center text-sm font-medium text-gray-900">{user.username}</td>
                      <td className="px-6 py-4 text-center text-sm font-medium text-gray-900">{user.points}</td>
                      <td className="px-6 py-4 text-center text-sm font-medium text-gray-900">{user.total_games}</td>
                      <td className="px-6 py-4 text-center text-sm font-medium text-gray-900">{user.user_level}</td>
                      <td className="px-6 py-4 text-center text-sm font-medium text-gray-900">{user.user_location}</td>
                      <td className="px-6 py-4 text-center text-sm font-medium text-gray-900">{user.date_joined}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8" className="text-center px-4 py-2">{t("scoreboard_page.no_users")}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Scoreboard;