import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useNavigate } from 'react-router-dom';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_TOKEN;

const MapboxMap = ({ locations, selectedCityCoordinates }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const markersRef = useRef([]);
  const navigate = useNavigate();

  // Initialize map
  useEffect(() => {
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [-6.2603, 53.3498],
      zoom: 10,
    });

    mapRef.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    return () => mapRef.current.remove();
  }, []);

  // Add markers and handle click events
  useEffect(() => {
    if (mapRef.current && locations) {
      // Remove existing markers
      markersRef.current.forEach((marker) => marker.remove());
      markersRef.current = [];

      // Add new markers
      locations.forEach((location) => {
        const { latitude, longitude } = location.coordinates;
        const markerLng = latitude;
        const markerLat = longitude;

        const marker = new mapboxgl.Marker()
          .setLngLat([markerLng, markerLat])
          .addTo(mapRef.current);

        // Direct navigation on marker click
        marker.getElement().addEventListener('click', () => {
          navigate(`/location/${location.id}`);
        });

        markersRef.current.push(marker);
      });
    }
  }, [locations, navigate]);

  // Update map center when city coordinates change
  useEffect(() => {
    if (mapRef.current && selectedCityCoordinates) {
      mapRef.current.flyTo({
        center: [selectedCityCoordinates.lng, selectedCityCoordinates.lat],
        zoom: 12,
        essential: true,
      });
    }
  }, [selectedCityCoordinates]);

  return <div ref={mapContainerRef} style={{ height: '500px', width: '100%' }} />;
};

export default MapboxMap;
