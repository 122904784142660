import React from 'react';
import { useTranslation } from 'react-i18next';

// Helper function to format date and time
const formatDate = (dateString) => {
  const [datePart, timePart] = dateString.split(' ');
  const [day, month, year] = datePart.split('-');
  const [hours, minutes] = timePart.split(':');

  // Create a Date object
  const date = new Date(year, month - 1, day, hours, minutes);

  // Format date and time
  const formattedDate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

  return { formattedDate, formattedTime };
};

const EventCard = ({ event, onClick }) => {
  const { t } = useTranslation();
  const { formattedDate, formattedTime } = formatDate(event.event_date);

  return (
    <li
      className="event-item bg-white p-4 rounded shadow-md cursor-pointer"
      onClick={onClick}
      style={{ listStyle: 'none' }}
    >
      <h2 className="text-xl font-semibold mb-2">{event.category}</h2>
      <p className="mb-1"><strong>{t('event_card.label_date')}</strong> {formattedDate}</p>
      <p className="mb-1"><strong>{t('event_card.label_time')}</strong> {formattedTime}</p>
      <p className="mb-1"><strong>{t('event_card.label_address')}</strong> {event.event_address}, {event.event_city}</p>
    </li>
  );
};

export default EventCard;