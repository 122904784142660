import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useTranslation } from "react-i18next";

const LocationReview = ({ onClose, locationId }) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const validationSchema = Yup.object({
    comment: Yup.string().nullable(),
    rating: Yup.number()
      .required('Rating is required')
      .min(1, 'Rating must be at least 1')
      .max(5, 'Rating cannot exceed 5'),
  });

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [success, onClose]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const accessToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('access_token='))
        ?.split('=')[1];

      if (!accessToken) {
        alert("Please log in to submit a review.");
        return;
      }

      await axios.post(`user_actions/v1/create-location-review/${locationId}/`, values, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      setSuccess(true);
      resetForm();
      setError('');
    } catch (error) {
      setSuccess(false);

      if (error.response) {
        if (error.response.status === 400) {
            setError('You have already reviewed this location.');
        } else {
          setError('An error occurred. Please try again.');
        }
      } else {
        setError('Network error. Please try again later.');
      }
      console.error('Error submitting review:', error);
    } finally {
      setSubmitting(false);
    }
  };

  if (success) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75 px-4">
        <div className="bg-white rounded-lg shadow-lg p-6 md:p-8 text-center">
          <div className="text-green-600 text-xl font-semibold mb-4">
            {t("location_review_component.success_message")}
          </div>
          <div className="text-gray-600">
            {t("location_review_component.thanks_message")}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-75 px-4"
      onClick={onClose}
    >
      <div
        className="relative w-full max-w-md bg-white rounded-lg shadow-lg p-6 md:p-8 lg:p-10 max-h-[90vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
        >
          ×
        </button>

        <h3 className="text-lg font-semibold mb-6 text-center">{t("location_review_component.leave_review")}</h3>

        <Formik
          initialValues={{
            comment: '',
            rating: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-6">
              {error && (
                <div className="text-red-600 mb-4 text-center font-semibold">
                  {error}
                </div>
              )}

              <div className="form-group">
                <label htmlFor="comment" className="label block font-semibold mb-1">
                  {t("location_review_component.comment_optional")}
                </label>
                <Field
                  as="textarea"
                  name="comment"
                  placeholder={t("location_review_component.comment_placeholder")}
                  className="textarea-field block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <ErrorMessage
                  name="comment"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="form-group">
                <label htmlFor="rating" className="label block font-semibold mb-1">
                  {t("location_review_component.rating")}
                </label>
                <Field
                  as="select"
                  name="rating"
                  className="input-field block w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="" label={t("location_review_component.label_select_rating")} />
                  {[1, 2, 3, 4, 5].map((rating) => (
                    <option key={rating} value={rating}>
                      {rating} 🌟
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="rating"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>

              <div className="flex justify-between items-center">
                <button
                  type="reset"
                  className="reset-button px-4 py-2 bg-gray-200 hover:bg-gray-300 rounded-lg font-semibold text-gray-700"
                >
                  {t("location_review_component.reset")}
                </button>
                <button
                  type="submit"
                  className="submit-button px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg font-semibold"
                  disabled={isSubmitting}
                >
                  {t("location_review_component.submit")}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default LocationReview;
