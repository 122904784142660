import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import getCookie from '../interceptors/axios';
import './AddCourt.css';
import { setAuth } from '../redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const AddCourt = () => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone();
  const [cities, setCities] = useState([]);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get('content/v1/cities/');
        setCities(response.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };

    fetchCities();
  }, []);

  const handleFormSubmit = async (values, acceptedFiles, setSubmitting) => {
    const token = getCookie('access_token');
    console.log(token);

    if (!token) {
      setError('Login or signup');
      setSubmitting(false);
      dispatch(setAuth(true));
      return;
    }

    const formData = new FormData();
    formData.append('address', values.address);
    formData.append('name', values.name);
    formData.append('description', values.description);
    formData.append('city', values.city);

    acceptedFiles.forEach((file, index) => {
      formData.append(`uploaded_location_submission_image`, file);
    });

    try {
      const response = await axios.post('user_actions/v1/submit-location/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Court added successfully:', response.data);

      if (response.status === 201) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 1200);
      }

      setError('');
    } catch (error) {
      if (error.response) {
        const errorResponse = error.response.data;
        let errorMessage = 'An unexpected error occurred. Please try again later.';

        if (errorResponse.name && errorResponse.name[0] === "location submission with this name already exists.") {
          errorMessage = 'location submission with this name already exists.';
        } else if (errorResponse.uploaded_location_submission_image) {
          errorMessage = errorResponse.uploaded_location_submission_image.join(' ');
        }

        if (errorResponse.name && errorResponse.name[0] === "Ensure this field has no more than 50 characters.") {
          errorMessage = 'Ensure this field has no more than 50 characters.';
        } else if (errorResponse.uploaded_location_submission_image) {
          errorMessage = errorResponse.uploaded_location_submission_image.join(' ');
        }

        setError(errorMessage);
      } else {
        setError('Network error. Please check your internet connection.');
      }
      console.error('Error adding court:', error);
    } finally {
      setSubmitting(false); 
    }
  };

  return (
    <div className="add-court-container">
      <h1 className="title">{t("add_court_component.add_court_message")}</h1>
      {success && (
        <div className="alert-success" role="alert">
          {t("add_court_component.success_message")}
        </div>
      )}
      {!success && (
        <Formik
          initialValues={{ address: '', name: '', description: '', city: '' }}
          validationSchema={Yup.object({
            address: Yup.string().required('Required'),
            name: Yup.string().required('Required'),
            description: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            handleFormSubmit(values, acceptedFiles, setSubmitting);
          }}
        >
          <Form className="form">
            {error && (
              <div className="alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="form-group">
              <label htmlFor="city" className="label">{t("add_court_component.city")}</label>
              <Field as="select" name="city" className="input-field">
                <option value="">{t("add_court_component.chooseCity")}</option>
                {cities.map((city) => (
                  <option key={city.id} value={city.name}>{city.name}</option>
                ))}
              </Field>
              <ErrorMessage name="city" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="address" className="label">{t("add_court_component.address")}</label>
              <Field name="address" type="text" className="input-field" />
              <ErrorMessage name="address" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="name" className="label">{t("add_court_component.add_location_name")}</label>
              <Field name="name" type="text" className="input-field" />
              <ErrorMessage name="name" component="div" className="error-message" />
            </div>

            <div className="form-group">
              <label htmlFor="description" className="label">{t("add_court_component.brief_description")}</label>
              <Field name="description" as="textarea" className="textarea-field" />
              <ErrorMessage name="description" component="div" className="error-message" />
            </div>

            <div {...getRootProps()} className="dropzone">
              <input {...getInputProps()} />
              <p>{t("add_court_component.dragDrop")}</p>
            </div>
            <div>
              <h4>{t("add_court_component.accepted_files")}</h4>
              <ul>
                {acceptedFiles.map(file => (
                  <li key={file.path}>{file.path}</li>
                ))}
              </ul>
            </div>

            <div className="buttons">
              <button type="reset" className="reset-button">{t("add_court_component.reset")}</button>
              <button type="submit" className="submit-button">{t("add_court_component.add")}</button>
            </div>
          </Form>
        </Formik>
      )}
    </div>
  );
};

export default AddCourt;
